
import './MarketSignals.scss'
import trendLine from '../../../images/trend_line.png'
import marketSignalsPhonesNoShadow from '../../../images/market_signals_phones_no_shadow.png'
// import marketSignalsPhoneLeft from '../../../images/market_signals_phone_left.png'
// import marketSignalsPhoneRight from '../../../images/market_signals_phone_right.png'
import marketSignalsPhonesShadow from '../../../images/market_signals_phones_shadow.png'
import Page from '../../Page';
import { motion, useInView } from "motion/react"
import { useRef } from 'react';
import { useMediaQuery } from 'react-responsive';

function MarketSignals(props) {
    const scrollTriggerRef = useRef(null)
    const scrollTriggerInView = useInView(scrollTriggerRef, {once: true})

    const isDefault = useMediaQuery({ minWidth: 768 })

    const variants = {
        stop: { translateX: 0 },
        start: { translateX: "100vw" },
    }

    //https://www.framer.com/docs/transition/#spring for info about the different variables
    var transition ={ duration: 3, type: 'spring', stiffness: 50, damping: 10, bounce: 0.1, mass: 10 }
    var trandLineDuration = isDefault ? 5 : 3
    
    return (
        <Page className="market-signals-page" bgImage={trendLine} style={{backgroundPosition: !isDefault ? '35% 50%' : '60% 50%'}}>
            <motion.div 
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    backgroundColor: '#EAE7DF',
                }}
                animate={scrollTriggerInView ? 'start' : 'stop'}
                variants={variants}
                transition={{ type: 'linear', duration: trandLineDuration }}
                viewport={{ once: true }}
            />
            <motion.img 
                initial={{ translateY: 100, x: isDefault ? 0 : '-50%' }}
                whileInView={{ translateY: 0, x: isDefault ? 0 : '-50%' }}
                transition={transition}
                viewport={{ once: true }}
                style={{zIndex: 2}}
                src={marketSignalsPhonesNoShadow} 
                className="Market-signals-phones" 
                alt="Market signals phone"
            />
            <motion.img 
                initial={{ translateY: 75, x: isDefault ? '20%' : '-30%' }}
                whileInView={{ translateY: 25, x: isDefault ? '20%' : '-30%' }}
                transition={transition}
                viewport={{ once: true }}
                src={marketSignalsPhonesShadow} 
                className="Market-signals-phones" 
                alt="Market signals phone"
            />
            <div className="Market-signals-text-container">
                {/* <div className='Market-signals-text-sub-container'>  */}
                    <h2 ref={scrollTriggerRef} className="title-medium">Get market <span className="Orange-text">signals</span> directly to your phone</h2>
                    <p className="page-subtitle">Set up trend signal alerts, activating push notifications directly to your phone.</p>
               {/*  </div>  */}
                {/* {isDefault && <hr/>}  */}
            </div>
        </Page>
    )
}

export default MarketSignals