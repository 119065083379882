import appStoreImage from '../images/app_store.png'
// import googlePlayImage from '../images/google_play.png'

function Download() {

    return (
        <div className="Download-section">
            <div>
                <img src={appStoreImage} className="App-download-link" alt="App store download"/>
            </div>
            {/* <div>
                <img src={googlePlayImage} className="App-download-link" alt="Google play download"/>
            </div> */}
        </div>
    )
}

export default Download