import './IndicatorLevel.scss'
import indicatorLevelMedium from '../../../images/indicator_level_medium.png'
import indicatorLevelLong from '../../../images/indicator_level_long.png'
import indicatorLevelReverse from '../../../images/indicator_level_reverse.png'
import kimiSignalsLogo from '../../../images/kimi_signals_logo.png'
import kimiNotification from '../../../images/example_notification.png'
import Level from "./Level"
import Page from '../../Page'
import { useRef } from 'react'
import { motion, useInView } from 'motion/react'
// import getIOSversion from '../../../getIOSVersion'

function IndicatorLevels() {

    const ref = useRef(null)

    const notificationTriggerRef = useRef(null)
    const notificationTriggerInView = useInView(notificationTriggerRef, {once: true})
    const notificationAnimVariants = {
        stop: { translateY: "1000px" },
        start: { translateY: "0px" },
    }

    return (
        <Page className="indicator-level-page" >
            <div className='Indicator-level-text-container'>
                <div className='Indicator-level-title-container'>
                    <h2 className="title-xlarge">Choose your <span className="Ital-text">stocks</span> and use these features</h2>
                </div>
                <div ref={ref} className="Indicator-level-phones">
                        <Level
                            image={indicatorLevelMedium}
                            logo={kimiSignalsLogo}
                            title="Curate your watchlist"
                            text="Select from over 5000 US stocks to set up your trackers"
                        />
                        <Level 
                            image={indicatorLevelLong}
                            logo={kimiSignalsLogo}
                            title="Dive into the data"
                            text="View charts, prices and indicators for your stocks"
                        />
                        <Level 
                            image={indicatorLevelReverse}
                            logo={kimiSignalsLogo}
                            title="Choose your indicators"
                            text="Select which trends to track and when to receive alerts"
                        />
                </div>
                <div className='example-notification-container'>
                    <motion.img 
                        animate={notificationTriggerInView ? 'start' : 'stop'}
                        variants={notificationAnimVariants}
                        transition={{ type: 'spring', duration: 1 }}
                        viewport={{ once: true }}
                        src={kimiNotification} 
                        className='example-notification-image'
                    />
                    <p className='title-small'>Now start receiving your Kimi Signal alerts</p>
                </div>
                <div ref={notificationTriggerRef} style={{position: 'absolute', top: '140vh'}}></div>
            </div>
        </Page>
    )
}

export default IndicatorLevels