import './App.scss';
import kimiSignalsLogo from './images/kimisignals-footer-logo.svg'
import { Link, Outlet } from 'react-router-dom';
import { useEffect } from 'react';


function App() {

    useEffect(() => {
        document.title = "Kimi Signals"
        window.onbeforeunload = () => {
            window.scrollTo(0, 0);
        }
    }, [])

    return (
    <div className="App">
        <header className="App-header">
            <Link to={"/"} className="App-title">KIMISIGNALS</Link>
        </header>
        <div className="Page-container">
            <div className="Content-wrap">
                <Outlet/>
            </div>
            <footer className="App-footer">
                <div className="Footer-nav-links">
                    <img src={kimiSignalsLogo} className="footer-kimi-signals-logo" alt="Logo"/>
                    <Link to={"contactus"} className="Footer-link">CONTACT US</Link>
                    <Link to={""} className="Footer-link">APP STORE</Link>
                    <Link to={"terms"} className="Footer-link">TERMS</Link>
                    <Link to={"privacy"} className="Footer-link">PRIVACY</Link>
                </div>
                <div className="Footer-copyright"> © KIMISIGNALS 2025</div>
            </footer>
        </div>
    </div>
  );
}

export default App;
