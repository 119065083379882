

function Level(props) {

    return (
        <div className="Indicator-level-phone-container">
            {/* <div> */}
                <img src={props.image} className="Indicator-level-phone-image" alt="phone" />
            {/* </div> */}
            <div className="Indicator-level-phone-bg">
                {/* <div>
                    <img src={props.logo} className="Indicator-level-logo" alt="logo" />
                </div> */}
                <div className="Indicator-level-phone-title title-medium">{props.title}</div>
                <div className="Indicator-level-phone-body page-subtitle">{props.text}</div>
            </div>
        </div>
    )
}

export default Level